import { createTheme, ThemeProvider } from "@material-ui/core";
import { useMemo } from "react";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolflareWebWallet,
    getSolletWallet
} from "@solana/wallet-adapter-wallets";

import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';

import { DEFAULT_TIMEOUT } from './connection';
import {Home} from "./pages/Home";
import Mint from "./pages/Mint";
import Terms from "./pages/Terms";
import Degens from "./pages/Degens";
import Privacy from "./pages/Privacy";
import './scss.scss'
import Footer from "./Footer";

require('@solana/wallet-adapter-react-ui/styles.css');

const candyMachineId = new anchor.web3.PublicKey(
    process.env.REACT_APP_CANDY_MACHINE_ID!,
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const theme = createTheme({palette: {type: 'dark'}});

const App = () => {
    const endpoint = useMemo(() => clusterApiUrl(network), []);

    const wallets = useMemo(() => [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getSolflareWebWallet(),
        getSolletWallet({ network })
    ], []);



    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets} autoConnect={true}>
                        <WalletModalProvider>
                            <Routes>
                                <Route>
                                
                                <Route path="/" element={<Degens />} /> 
                                <Route path="/degens" element={<Home />} />   
                                <Route path="/terms" element={<Terms />} /> 
                                <Route path="/privacy" element={<Privacy />} /> 
                                <Route path="/mint" element={
                                    <Mint
                                        candyMachineId={candyMachineId}
                                        connection={connection}
                                        txTimeout={DEFAULT_TIMEOUT}
                                        rpcHost={rpcHost}
                                        network={network} />
                                } /> 
                                </Route>
                            </Routes>

                            <Footer/>

                        </WalletModalProvider>
                    </WalletProvider>
                </ConnectionProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
};


/*
   <Snackbar
        open={isCookies}
        autoHideDuration={null}
        anchorOrigin={{vertical: 'top', horizontal: 'center' }}
        onClose={() => setIsCookies(false)}>
        <Alert
            onClose={() => setIsCookies(false)}  
            action={<Button color="secondary" size="small" onClick={() => setIsCookies(false)}>
            ACCEPT
            </Button>}                        
            severity="info">
            We use cookies like every other website in the world because thats how u store data in the browser and communism sucks!
        </Alert>
    </Snackbar> 
*/

export default App;
