import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useWallet } from "@solana/wallet-adapter-react";

import { useWalletModal } from "@solana/wallet-adapter-react-ui";

import MoviekeysLogo from '../assets/moviekeys-logo.svg';
import MkInCart from '../assets/mk-in-cart.png';

import CurrencyIcon1 from '../assets/currency-icon-1.svg';
import CurrencyIcon2 from '../assets/currency-icon-2.svg';

import "./Mint.css"

const Degens = () => {
    const [buttonClicked, setButtonClicked] = useState(false);
    const nav = useNavigate()
    const {setVisible} = useWalletModal();
    
    const wallet = useWallet();
    
    useEffect(() => {
        if (wallet.connected && buttonClicked) {
            nav("/mint")
        }
    }, [wallet])

    const selectWallet = () => {
        setVisible(true)
        setButtonClicked(true)
    }

    const goToMint = () => nav("/mint")
  
    return (
        <div className="purchase-main">
            <div className="moviekeys-purchase-header-wrapper">
                <Link to="/" className="moviekeys-purchase-header-inner">
                    <div className="moviekeys-purchase-header-inner-img">
                        <img src={MoviekeysLogo} alt="" />
                    </div>
                    <div className="moviekeys-purchase-header-inner-text">
                        <h2>MOVIEKEY</h2>
                        <p className="shine">Global streaming powered by MOVIEKEY</p>
                    </div>
                </Link>
                <div className="moviekeys-purchase-header-inner in-cart-main">
                    <div className="moviekeys-purchase-header-in-cart-text">
                        <h4>In Cart:</h4>
                        <p>NFTme Season 1 MovieKey</p>
                    </div>
                    <div className="moviekeys-purchase-header-in-cart-main-img">
                        <img src={MkInCart} alt="" />
                    </div>
                </div>
            </div>
            <div className="purchase-contains">
                <h5></h5>
                <div className='purchase-card-main'>
                    <h4 style={{fontSize: '18px', lineHeight: '24px'}}>Connect to your wallet to purchase NFTme Season 1 MovieKey </h4>
                    <div className='wallet-currency'>
                        <img src={CurrencyIcon1} alt="Solana" />
                        <img src={CurrencyIcon2} alt="USD Coin" />
                    </div>
                    <div className="buy-with-card-button">   
                    {!wallet.connected ? (
                    <div className='connect-wallet-button' style={{margin:'10px'}}>
                        {/* <a onClick={selectWallet} style={{fontSize:'30px'}} className="hm-cta-btn">SELECT WALLET</a> */}
                        <a onClick={selectWallet} style={{fontSize:'30px'}} className="hm-cta-btn new-adjustment-button">SELECT WALLET</a>
                        {/* <button className="wallet-adapter-button" onClick={selectWallet}>SELECT WALLET</button> */}
                            </div>
                        ) : (
                                <a onClick={goToMint} style={{fontSize:'30px'}} className="hm-cta-btn new-adjustment-button">GO TO MINT</a>
                        )}
                        <div className="div-block-8">
                            <div className="text-block-3 price-usd price-purchase">
                                <p className="purchase-price">$12.99</p>
                                <div className="purchase-label">
                                    <span className="usd">USDC</span>
                                    <span className="fees">+ fees</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='conditions-text'>By checking out with the above  option <br/> above you agree to our  <Link to='/terms' style={{textDecoration:"underline"}}>terms & conditions</Link></div>
                
            </div>
        </div>
    );
};

export default Degens;
