import {CandyMachineAccount} from './Candy';

interface MintBtnProps {
    onMint: () => Promise<void>;
    candyMachine?: CandyMachineAccount;
    isMinting: boolean;
    isActive: boolean;
    isEnded: boolean;
}

export const MintButton = (props: MintBtnProps) => {

    console.log(props.candyMachine?.state.goLiveDate);
    
    return (
        <button
            style={{fontSize:'30px',padding:'16px 20px',lineHeight:'1',background:'#A41122',border:'1px solid #FF8181',marginRight:'0px'}}
            className=' hm-cta-btn'
            disabled={props.isMinting || props.isEnded}
            onClick={async () => await props.onMint()}>
                {!props.candyMachine ? (
                    "CONNECTING..."
                ) : props.isActive ? (
                    props.isMinting ? "MINTING..." : "MINT MOVIEKEY"
                ) : props.isEnded ? "ENDED" : (props.candyMachine?.state.goLiveDate ? (
                    "SOON"
                ) : (
                    "UNAVAILABLE"
                ))}
        </button>
    );
};
