import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Joshua from '../assets/Joshua.jpg';
import Kevin from '../assets/Kevin.jpg';
import Roy from '../assets/ROY.png';
import upArrow from '../assets/down.svg';
import ALEXANDER from '../assets/ALEXANDER.png';
import Scott from '../assets/SCOTT.jpg';
import Helmut from '../assets/HELMUT.jpg';
import Anna from '../assets/ANNA.jpg';
import Calita from '../assets/CALITA.jpeg';
import airLogo from '../assets/appleair2.png';
import topBanner from '../assets/top-banner.png';
import Sarah from '../assets/SARAH.jpg';
import Bitmap from '../assets/Bitmap.png';
import device from '../assets/devices.png';
import tv from '../assets/tv.png';
import myVideo from '../assets/MKnewest.mp4'
import playIcon from '../assets/play.png'
import ModalVideo from 'react-modal-video'
import twitterIcon from '../assets/twitter.png';
import discordIcon from '../assets/discord.png';

import Modal from 'react-modal';
import safeLogo from '../assets/safe.png';
import close from '../assets/close.png';
import MLogo from '../assets/M.png'
import ReactPlayer from 'react-player';

import './Home.css';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';

interface AlertState {
    open: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
    hideDuration?: number | null;
}

const Home = () => {
    
    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });

    const [isOpen, setOpen] = useState(false)
    const [openNewsLetter, setOpenNewsLetter] = useState(false)

    const [nlname, setNlname] = useState('')
    const [nlEmail, setNlEmail] = useState('')
    const [createdNL, setCreatedNL] = useState(false)

    const [alreadyCreated, setAlreadyCreated] = useState(false)

    const useScrollPosition = () => {
        const [scrollPosition, setScrollPosition] = useState(0);
        useEffect(() => {
          const updatePosition = () => {
            setScrollPosition(window.pageYOffset);
          }
          window.addEventListener("scroll", updatePosition);
          updatePosition();
          return () => window.removeEventListener("scroll", updatePosition);
        }, [])
        return scrollPosition;
    };

    const scrollPosition = useScrollPosition();

    useEffect(() => {
       if(StripeDiv || CMDiv){
        setCMDiv(false)
        setStripeDiv(false)
       }
    }, [scrollPosition])
    


    const submitNL = () => {
        if(!nlname || !nlEmail) return ;
        axios.post('https://movie-api-de5gvdjqwa-ew.a.run.app/profiles/mailinglist' , {name : nlname , email:nlEmail}).then(x=>{
            if(x.status === 201 || x.status === 200 || x.status === 204 ){
                setCreatedNL(true)
                setTimeout(() => {
                    setOpenNewsLetter(false)
                }, 2500);
            }
            else{
                setAlreadyCreated(true)
                setTimeout(() => {
                    setOpenNewsLetter(false)
                }, 2500);
            }
        })
    }

    const [CMDiv, setCMDiv] = useState(false)
    const [StripeDiv, setStripeDiv] = useState(false)
    const [faq, setfaq] = useState<any>(0)
    const inputElement :any = useRef();
    const [isOpen2, setOpen2] = useState(false)
    const [play2, setPlay2] = useState(false)

    const openCrossMint = () => {
        setCMDiv(!CMDiv)
        setStripeDiv(false)
        setTimeout(() => {
            setCMDiv(false)
        }, 6000);
    }

    const openStripe = () => {
        setStripeDiv(!StripeDiv)
        setCMDiv(false)
        setTimeout(() => {
            setStripeDiv(false)
        }, 6000);
    }

    const [buyTut, setBuyTut] = useState(false)
    const [airplayTut, setAirplayTut] = useState(false)

    return (
        <>

            <ModalVideo animationSpeed={0} channel='youtube'  isOpen={isOpen} videoId="wdXqoydTsHY" onClose={() => setOpen(false)} />
            <ModalVideo animationSpeed={0} channel='youtube'  isOpen={isOpen2} videoId="K-fZRlNAdos" onClose={() => setOpen2(false)} />
            
            <ModalVideo animationSpeed={0} channel='youtube'  isOpen={buyTut} videoId="hvuk3jJxzOc" onClose={() => setBuyTut(false)} />
            <ModalVideo animationSpeed={0} channel='youtube'  isOpen={airplayTut} videoId="5N-ua_GqaPs" onClose={() => setAirplayTut(false)} />
            

            <Modal
                className='the-modal'
                isOpen={openNewsLetter}
                onRequestClose={()=>setOpenNewsLetter(false)}
                contentLabel="Example Modal"
            >
            <div style={{textAlign:'center'}}>
                <div style={{textAlign:'right'}}><img src={close} className='the-x'  onClick={() => setOpenNewsLetter(false) }/></div>
                {/* <img src={mkLogo} width='90%'/> */}
                <p className='hm-details-heading' >Sign up for <span style={{fontWeight:'900'}} >MOVIEKEY</span> reminders</p>
                <input className='fiill hm-cta-btn' style={{fontSize:'16px',fontWeight:'600',background:'#333333',border:'0px'}} placeholder='Name' id='name' type='text' onChange={(e)=>setNlname(e.target.value)} />
                <input className='fiill hm-cta-btn' style={{fontSize:'16px',fontWeight:'600',background:'#333333',border:'0px'}} placeholder='Email' id='email' type='email' onChange={(e)=> setNlEmail(e.target.value) } />
                <button className='hm-cta-btn new-red-buy' style={{fontSize:'18px',width:'100%',padding:'12px',maxWidth:'250px',margin:'auto'}} onClick={submitNL} >{alreadyCreated ? 'Already Joined' : createdNL ? 'Joined !' : 'Join'}</button>
            </div>
            </Modal>


            <div className="home">
                
                <div className="w-row">

                    <div className="home-video smol-device">  
                        <FadeInSection  >
                            <div className='delay1s'>
                            <video
                                src={myVideo}
                                width="100%"
                                className="rounded-md"
                                autoPlay
                                loop
                                playsInline
                                muted>
                                Your Browser does not support videos
                            </video> 
                            </div>
                        </FadeInSection>
                
                        <div className="hm-cta-section" style={{paddingTop:'15px'}}>   
                            {/* <div className='hm-cta-description'> Purchase to watch online anytime  </div>   */}
                            <a href='https://watch.nftme.tv/player/connect?action=checkout' style={{padding:'11.5px 10px'}} className="main-buy-btn pre-order-btn new-red-buy">
                    
                                <p style={{ margin:'0px',fontSize:'26px',lineHeight:'1' }} >BUY NFTme $12.99</p>
                                <p style={{fontSize:'14px',fontWeight:'500',margin:'0px',lineHeight:'1',textTransform:'lowercase'}}  className='museo-sans'>+ transaction fee</p>

                            </a>
                            

                            
                            <a onClick={()=>setOpenNewsLetter(true)} style={{backgroundColor:'#1d1d1d',fontSize:'26px',margin:'0px'}} className="main-buy-btn pre-order-btn grey-big-btn new-grey-btn"> Remind Me Later </a>

                            <div className='new-secure' style={{maxWidth:'558px',margin:'12px auto',gap:'15px'}}>
                                <img src={safeLogo} style={{width:'30px',height:'32px'}} />
                                <p style={{fontSize:'18px'}}>Guaranteed Safe & Secure Checkout</p>
                            </div>
                        </div>              
                    </div>
                    
                    <div className="hm-details">

                        <FadeInSection>
                            <div>
                            <img className='hm-details-img ' src={Bitmap} width="385" alt="" />
                            </div>

                            <FadeInSection>
                            <div className='home-p'>
                            HOME PREMIERE
                            </div>
                            </FadeInSection>

                            <ul className="hm-details-genre" style={{marginTop:'0px'}} >
                                <li style={{marginTop:'0px'}}><h4 style={{fontSize:'15px'}} >Thriller</h4></li>
                                <li style={{marginTop:'0px'}}><h4 style={{fontSize:'15px'}} >2022</h4></li>
                                <li style={{marginTop:'0px'}}><h4 style={{fontSize:'15px'}}>Released In Theaters</h4></li>
                            </ul>
                        </FadeInSection>                        

                        <FadeInSection>
                            <div className="hm-details-desc"  style={{marginTop:'16px'}}>

                            In this gripping revenge thriller, streaming only
                            on MovieKey, two-time Oscar nominee Roy Scheider 
                            (The French Connection, All That Jazz, Jaws) 
                            gives a powerful performance as an NYPD cop
                            seeking revenge against the Nazi who murdered
                            his family in WW2 Europe. 
                            
                            
                            <p style={{marginTop:'8px'}} >Suitable for ages 15+</p>

                            </div>
                        </FadeInSection>


                        <div style={{margin:'10px 0px',marginTop:'20px'}}>
                            <FadeInSection>
                            <h3 className='hm-mkey-title' style={{textAlign:'left'}}>How to watch</h3>
                                
                                <div style={{overflowX:'auto'}} className='noshowscroll'>
                                <div style={{display:'flex',gap:'14px',width:'694px'}}>
                                <div className='how-tb1'>
                                <a href='https://watch.nftme.tv/connect?action=checkout'> 
                                    <div className='how-tb2'>
                                        <img src={MLogo} style={{height:'75.8px'}}/>
                                        <p style={{fontSize:'16px',fontWeight:'600',marginTop:'8px',marginBottom:'0px'}}>Pay Once.</p>
                                        <p style={{fontSize:'16px',fontWeight:'600',marginTop:'8px',marginBottom:'8px'}}>Own & Watch Instantly.</p>
                                    </div>
                                    <div className='how-tb3'>
                                        <p style={{marginTop:'0px'}}>$12.99 + transaction fee</p>
                                    </div>
                                </a>
                                </div>
                                <div className='how-tb1'>
                                <a> 
                                    <div className='how-tb2'>
                                        <img src={tv} style={{height:'75.8px'}}/>
                                        <p style={{fontSize:'16px',fontWeight:'600',marginTop:'8px',marginBottom:'0px'}}>Play on TV easily</p>
                                        <p style={{fontSize:'16px',fontWeight:'600',marginTop:'8px',marginBottom:'8px'}}>with Apple Airplay</p>
                                    </div>
                                    <div className='how-tb3'>
                                    <p style={{marginTop:'0px'}}>It's fast and simple</p>
                                    </div>
                                </a>
                                </div>
                                <div className='how-tb1'>
                                <a> 
                                    <div className='how-tb2'>
                                        <img src={device} style={{height:'75.8px'}}/>
                                        <p style={{fontSize:'16px',fontWeight:'600',marginTop:'8px',marginBottom:'0px'}}>Watch online</p>
                                        <p style={{fontSize:'16px',fontWeight:'600',marginTop:'8px',marginBottom:'8px'}}>on all your devices</p>
                                    </div>
                                    <div className='how-tb3'>
                                        <p style={{marginTop:'0px'}}>No download needed</p>
                                    </div>
                                </a>
                                </div>
                                </div>
                                </div>
                                
                            </FadeInSection>

                            </div>

                            <div style={{margin:'10px 0px',marginTop:'12px', display:'flex',gap: window.innerWidth > 500 ? '20px' : '12px' }}>
                            
                        </div>

                        <FadeInSection>
                            <p style={{marginTop:'12px',fontSize:'18px',textDecoration:'underline'}} ><a href="#info" >More Info</a></p>
                        </FadeInSection>
                                            
                            {/* trailer */}
                            <FadeInSection>
                                <div className='hm-details-trailers'>
                                    <div className="hm-details-trailer-block trailer-block">
                                        <h3 className="hm-details-heading">Trailer</h3>
                                        <div onClick ={ async () => {
                                                            setOpen(true)
                                                        }}  >
                                        {!isOpen2 ? <div style={{paddingTop: '56.17021276595745%'}} className="w-embed-youtubevideo youtube">
                                            
                                               
    
                                                    <ReactPlayer muted={true} loop={true} playing={play2} width='300px' height='150px' ref={inputElement} url={'https://www.youtube.com/watch?v=K-fZRlNAdos'} 
                                                        onPlay ={ () => {
                                                            setOpen2(true)
                                                            setPlay2(false)
                                                        }}
                                                    />
                                            </div>
                                             :
                                             <div style={{paddingTop: '56.17021276595745%'}} className="w-embed-youtubevideo youtube">
                                                    <iframe
                                                src="https://www.youtube.com/embed/K-fZRlNAdos?rel=0&amp;controls=0&amp;autoplay=0&amp;mute=1&amp;start=0"
                                                frameBorder="0" 
                                                title="BEAUTIFUL BLUE EYES | NEW ROY SCHEIDER MOVIE (2022) 4K trailer"></iframe>
                                            </div>
                                            }
    
                                        
                                        </div>
                                    </div>
                                    <div className="hm-details-trailer-block trailer-block">
                                        <h3 className="hm-details-heading">Song From The Movie</h3>
                                        <div>
                                        {!isOpen ? <div style={{paddingTop: '56.17021276595745%'}} className="w-embed-youtubevideo youtube">
                                                    <ReactPlayer muted={true} loop={true} width='300px' height='150px' ref={inputElement} url={'https://www.youtube.com/watch?v=wdXqoydTsHY'} 
                                                        onPlay ={ () => {
                                                            setOpen(true)
                                                        }}
                                                    />
                                        </div>
                                        :    
                                        <div style={{paddingTop: '56.17021276595745%'}} className="w-embed-youtubevideo youtube">
                                        <iframe
                                            src="https://www.youtube.com/embed/wdXqoydTsHY?rel=0&amp;controls=0&amp;autoplay=0&amp;mute=1&amp;start=0"
                                            frameBorder="0" 
                                            title="Alexander Newton - Beautiful Blue Eyes"></iframe>
                                        </div>
                                    }
                                        </div>
                                    </div>
                                </div>
                            </FadeInSection>
    
                            {/* reviews */}
                            <FadeInSection>
                                <div className="hm-details-section">
                                    <h3 className="hm-details-heading">Review Quotes</h3>
                                    <div className="hm-details-reviews">
                                        <div>
                                            <p>“A beautifully crafted movie that will have audiences talking long afterwards” </p>
                                            <h3>Pete Hammond, Box Office Magazine</h3>
                                        </div>
                                        <div>
                                            <p>“A rollercoaster of a revenge thriller”</p>
                                            <h3>Bill Harris, E! Entertainment Television </h3>
                                        </div>
                                        <div>
                                            <p>“An unforgettable, searing final performance from two-time Oscar® nominee Roy Scheider”</p>
                                            <h3>Shelli Sonstein, Clear Channel </h3>
                                        </div>
                                        <div>
                                            <p >“Alexander Newton is a complete standout in an extremely demanding role”</p>
                                            <h3>Claire Connors, Shape Magazine </h3>
                                        </div>
                                        <div>
                                            <p >“A taut, engrossing psychological thriller”</p>
                                            <h3>Avi Offer, NYC Movie Guru </h3>
                                        </div>
                                        <div>
                                            <p>“A Memorable and Moving Final Performance by Roy Scheider in a Heartfelt Film.”</p>
                                            <h3>Bill Bregoli, CBS Radio </h3>
                                        </div>
                                    </div>
                                </div>
                            </FadeInSection>

                        {/* cast */}
                        <FadeInSection>
                            <div className="hm-details-section">
                                <h3 className="hm-details-heading">Cast &amp; Crew</h3>
                                <div className="hm-details-cast">
                                    
                                    <div>
                                        <img src={Roy} alt="Roy Scheider" />
                                        <h3>Roy Scheider</h3>
                                        <h2  id='info' >Joseph</h2>
                                    </div>
                                    <div>
                                        <img src={ALEXANDER} alt="Alexander Newton" />
                                        <h3>Alexander Newton</h3>
                                        <h2>Young Joseph</h2>
                                    </div>
                                    <div>
                                        <img src={Scott} alt="Scott Cohen" />
                                        <h3>Scott Cohen</h3>
                                        <h2>Ronnie</h2>
                                    </div>
                                    <div>
                                        <img src={Helmut} alt="Helmut Berger" />
                                        <h3>Helmut Berger</h3>
                                        <h2>Shrager</h2>
                                    </div>
                                    <div>
                                        <img src={Anna} alt="Anna Polony" />
                                        <h3>Anna Polony</h3>
                                        <h2>Frau Ganz</h2>
                                    </div>
                                    <div>
                                        <img src={Calita} alt="Calita Rainford" />
                                        <h3>Calita Rainford</h3>
                                        <h2>Anna</h2>
                                    </div>
                                    <div>
                                        <img src={Sarah} alt="Sarah Bolger" />
                                        <h3>Sarah Bolger</h3>
                                        <h2>Kashka</h2>
                                    </div>
                                    <div>
                                        <img src={Kevin} alt="Kevin Farr" />
                                        <h3>Kevin Farr</h3>
                                        <h2>Producer</h2>
                                    </div>
                                    <div>
                                        <img src={Joshua} alt="Joshua Newton" />
                                        <h3>Joshua Newton</h3>
                                        <h2>Writer - Director<br />Producer</h2>
                                    </div>
                                </div>
                            </div>
                        </FadeInSection>


                        <FadeInSection>
                            <div style={{display:'flex',background:'#4C4C4C',marginTop:'10px',borderRadius:'8px',paddingLeft:'18px',justifyContent:'space-between',cursor:'pointer',maxWidth:'420px'}} onClick={()=>setfaq( faq === 1 ? 0 : 1)}>
                                <p style={{textAlign:'left',fontSize:'24px'}}>WATCH ON TV</p>
                                <div style={{width:'50px'}}>
                                    <img src={upArrow}  style={{ transform: faq !== 1 ? 'rotate(0deg)' : 'rotate(180deg)' ,width:'40px',height:'auto',marginLeft:'-10px',marginTop:'22px',marginBottom:'-15px',marginRight:'10px'}} />
                                </div>
                            </div>
                        </FadeInSection>
                            
                            { faq === 1 && <FadeInSection>
                            <p style={{fontSize:'18px',maxWidth:'420px'}} >
                            Cast <span style={{fontWeight:'900'}} >MOVIEKEY</span> to your TV from your phone & other devices: 
                            </p>

                            <FadeInSection>
                            <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',margin:'20px 0px',maxWidth:'420px'}}>
                                <div style={{display:'flex',alignItems:'center'}} >
                                    <div style={{fontSize:'96px', width: window.innerWidth < 600 ? '70px' : '85px' }}>1</div>
                                    <div className='cream-line' />
                                </div>
                                <div  className="hm-details-desc"  style={{width:'165px',fontSize:'16px',margin:'auto',padding:'12px'}}>Buy via the secure <br/> payment gateway</div>
                                <div className='tut-box' onClick={()=>setBuyTut(true)} > <img src={playIcon}/> <p> {buyTut ? 'Playing now' : 'Play tutorial' } </p> </div>
                            </div>
                            </FadeInSection>
                            {/* style={{opacity:'0'}} */}
                            <FadeInSection>
                            <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',margin:'20px 0px',maxWidth:'420px'}}>
                                <div style={{display:'flex',alignItems:'center'}} >
                                    <div style={{fontSize:'96px', width: window.innerWidth < 600 ? '70px' : '85px'}}>2</div>
                                    <div className='cream-line' />
                                </div>
                                <div className="hm-details-desc" style={{width:'165px',fontSize:'16px',margin:'auto',padding:'12px'}}>Navigate to <br/> <a href='https://watch.nftme.tv/player' >watch.nftme.tv/player</a> </div>
                                <div className='tut-box' style={{opacity:'0'}}> <img src={playIcon}/> <p>Play tutorial</p> </div>
                            </div>
                            </FadeInSection>

                            <FadeInSection>
                            <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',margin:'20px 0px',maxWidth:'420px'}}>
                                <div style={{display:'flex',alignItems:'center'}} >
                                    <div style={{fontSize:'96px', width: window.innerWidth < 600 ? '70px' : '85px'}}>3</div>
                                    <div className='cream-line' />
                                </div>
                                <div className="hm-details-desc"  style={{width:'175px',fontSize:'16px',margin:'auto',padding:'12px'}}>
                                    
                                Cast to TV via <br/> Apple Airplay
                                </div>
                                <div className='tut-box' onClick={()=>setAirplayTut(true)} > <img src={playIcon}/> <p> {airplayTut ? 'Playing now' : 'Play tutorial' } </p> </div>
                                
                            </div>
                            </FadeInSection>
                            
                            <FadeInSection>
                            <div style={{maxWidth:'420px',padding:'0px 20px',textAlign:'center'}}>
                            <img src={airLogo} style={{width:'40%'}} />
                            </div>
                            </FadeInSection>
                            
                            </FadeInSection>}


                        <FadeInSection>
                            <div style={{display:'flex',background:'#4C4C4C',marginTop:'10px',borderRadius:'8px',paddingLeft:'18px',justifyContent:'space-between',cursor:'pointer',maxWidth:'420px'}} onClick={()=>setfaq( faq === 2 ? 0 : 2)}>
                                <p style={{textAlign:'left',fontSize:'24px'}}>WATCH ON DEVICES</p>
                                <div style={{width:'50px'}}>
                                    <img src={upArrow}  style={{ transform: faq !== 2 ? 'rotate(0deg)' : 'rotate(180deg)' ,width:'40px',height:'auto',marginLeft:'-10px',marginTop:'22px',marginBottom:'-15px',marginRight:'10px'}} />
                                </div>
                            </div>
                        </FadeInSection> 


                        { faq === 2 && <FadeInSection>

                            <p style={{fontSize:'18px',maxWidth:'420px'}} >
                            How to play your <span style={{fontWeight:'900'}} >MOVIEKEY</span> on your phone, tablet, or computer:
                            </p>

                            <FadeInSection>
                            <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',margin:'20px 0px',maxWidth:'420px'}}>
                                <div style={{display:'flex',alignItems:'center'}} >
                                    <div style={{fontSize:'96px', width: window.innerWidth < 600 ? '70px' : '85px' }}>1</div>
                                    <div className='cream-line' />
                                </div>
                                <div  className="hm-details-desc"  style={{width:'165px',fontSize:'16px',margin:'auto',padding:'12px'}}>Buy via the secure <br/> payment gateway</div>
                                <div className='tut-box' onClick={()=>setBuyTut(true)} > <img src={playIcon}/> <p> {buyTut ? 'Playing now' : 'Play tutorial' } </p> </div>
                            </div>
                            </FadeInSection>

                            <FadeInSection>
                            <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',margin:'20px 0px',maxWidth:'420px'}}>
                                <div style={{display:'flex',alignItems:'center'}} >
                                    <div style={{fontSize:'96px', width: window.innerWidth < 600 ? '70px' : '85px'}}>2</div>
                                    <div className='cream-line' />
                                </div>
                                <div className="hm-details-desc" style={{width:'165px',fontSize:'16px',margin:'auto',padding:'12px'}}>Navigate to <br/> <a href='https://watch.nftme.tv/player'>watch.nftme.tv/player</a> </div>
                                <div className='tut-box' style={{opacity:'0'}}> <img src={playIcon}/> <p>Play tutorial</p> </div>
                            </div>
                            </FadeInSection>

                            <FadeInSection>
                            <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',margin:'20px 0px',maxWidth:'420px'}}>
                                <div style={{display:'flex',alignItems:'center'}} >
                                    <div style={{fontSize:'96px', width: window.innerWidth < 600 ? '70px' : '85px'}}>3</div>
                                    <div className='cream-line' />
                                </div>
                                <div className="hm-details-desc"  style={{width:'165px',fontSize:'16px',margin:'auto',padding:'12px'}}>Press play!</div>
                                <div className='tut-box' style={{opacity:'0'}}> <img src={playIcon}/> <p>Play tutorial</p> </div>
                            </div>
                            </FadeInSection>
                            </FadeInSection>}


                        <FadeInSection>
                            <div style={{display:'flex',background:'#4C4C4C',marginTop:'10px',borderRadius:'8px',paddingLeft:'18px',justifyContent:'space-between',cursor:'pointer',maxWidth:'420px'}} onClick={()=>setfaq( faq === 3 ? 0 : 3)}>
                                <p style={{textAlign:'left',fontSize:'24px'}}><span style={{fontWeight:'900'}} >MOVIEKEY</span> EXCLUSIVE</p>
                                <div style={{width:'50px'}}>
                                    <img src={upArrow}  style={{ transform: faq !== 3 ? 'rotate(0deg)' : 'rotate(180deg)' ,width:'40px',height:'auto',marginLeft:'-10px',marginTop:'22px',marginBottom:'-15px',marginRight:'10px'}} />
                                </div>
                            </div>
                        </FadeInSection>


                        {faq === 3 && <FadeInSection>
                            <p className='hm-mkey-title' style={{textAlign:'left'}}>Can I find this movie anywhere else?</p>
                            <p  className="hm-details-desc" style={{marginTop:'-2px',fontSize:'18px',marginBottom:'16px'}}>
                            You can find NFTme on <span style={{fontWeight:'900'}} >MOVIEKEY</span> and Amazon.
                            </p>
                        </FadeInSection>}


                        <FadeInSection>
                            <div style={{display:'flex',background:'#4C4C4C',marginTop:'10px',borderRadius:'8px',paddingLeft:'18px',justifyContent:'space-between',cursor:'pointer',maxWidth:'420px'}} onClick={()=>setfaq( faq === 4 ? 0 : 4)}>
                                <p style={{textAlign:'left',fontSize:'24px'}}>WHY <span style={{fontWeight:'900'}} >MOVIEKEY</span>?</p>
                                <div style={{width:'50px'}}>
                                    <img src={upArrow}  style={{ transform: faq !== 4 ? 'rotate(0deg)' : 'rotate(180deg)' ,width:'40px',height:'auto',marginLeft:'-10px',marginTop:'22px',marginBottom:'-15px',marginRight:'10px'}} />
                                </div>
                            </div>
                        </FadeInSection>

                        {faq === 4 && <FadeInSection>
                        <p  className='hm-mkey-title' style={{textAlign:'left'}}>Why MOVIEKEY?</p>
                            <ul className="hm-details-desc" style={{marginTop:'-2px',fontSize:'18px'}}>
                                <li>It’s like owning a BluRay, but completely online, with no extra hardware needed!</li>
                                <br/>
                                <li>Pay once. It’s yours to watch forever.</li>
                                <br/>
                                <li>Gift to friends after watching for free!</li>
                                <br/>
                                <li>Display as a digital collectible on Instagram.</li>
                            </ul>
                        
                        <div style={{display:'flex',marginBottom:'11px',marginTop:'12px'}} className='tut-rect'>
                            <img src={playIcon}/>
                            <p className="hm-details-desc" style={{margin:'0px'}} >Watch tutorial on gifting</p>
                        </div>
                        
                        <div style={{display:'flex',marginBottom:'16px'}} className='tut-rect'>
                            <img src={playIcon}/>
                            <p className="hm-details-desc" style={{margin:'0px'}}>How to display on Instagram</p>
                        </div>

                        </FadeInSection>}


                            
                        <FadeInSection>
                            <div style={{display:'flex',background:'#4C4C4C',marginTop:'10px',borderRadius:'8px',paddingLeft:'18px',justifyContent:'space-between',cursor:'pointer',maxWidth:'420px'}} onClick={()=>setfaq( faq === 5 ? 0 : 5)}>
                                <p style={{textAlign:'left',fontSize:'24px'}}>IS <span style={{fontWeight:'900'}} >MOVIEKEY</span> SECURE?</p>
                                <div style={{width:'50px'}}>
                                    <img src={upArrow}  style={{ transform: faq !== 5 ? 'rotate(0deg)' : 'rotate(180deg)' ,width:'40px',height:'auto',marginLeft:'-10px',marginTop:'22px',marginBottom:'-15px',marginRight:'10px'}} />
                                </div>
                            </div>
                        </FadeInSection>

                        {faq === 5 && <FadeInSection>

                            <p className='hm-mkey-title' style={{textAlign:'left'}}>How can I trust this platform?</p>

                            <p className="hm-details-desc" style={{marginTop:'-2px',fontSize:'18px'}}> 
                            We are committed to providing the best streaming experience for audiences.<br/><br/>
                            
                            Card payments are secured by <div className='cm-here' onClick={openCrossMint}>Crossmint 
                                {CMDiv && <div className='cm-div' >Crossmint™ helps us deliver your blockchain secured <span style={{fontWeight:'900'}} >MOVIEKEY™</span></div>} 
                            </div>
                                
                            {' &  '}
                            
                            <div className='cm-here' onClick={openStripe}>Stripe
                                {StripeDiv && <div className='cm-div' style={{right:'auto'}} >Stripe™ secures card transactions for Crossmint™ & <span style={{fontWeight:'900'}} >MOVIEKEY™</span></div>} 
                            </div>™  
                            
                            <br/><br/>
                            
                            And your personal data is always stored securely.<br/><br/>
                            
                            MOVIEKEY.io is SSL encrypted.<br/><br/>
                                
                            </p>

                            <div style={{display:'flex'}} className='tut-rect'>
                                <img src={playIcon}/>
                                <p className="hm-details-desc" style={{margin:'0px'}}>Watch welcome video</p>
                            </div>

                        </FadeInSection>}


                        <div style={{maxWidth:'420px'}}>

                        <FadeInSection><br/>
                                <p className='follow-up' >Follow on social media</p>
                                <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'12px'}} >
                                    <a href='https://twitter.com/watchmoviekey' ><img style={{height:'24px',width:'auto'}} src={twitterIcon}/></a>
                                    <a href='https://discord.gg/8muHz4zC' ><img style={{height:'26px',width:'auto'}} src={discordIcon}/></a>
                                </div>
                        </FadeInSection>

                        <FadeInSection>
                            <div className="px-3 sm:px-6 max-w-[1169px] mx-auto w-full " style={{marginTop:'50px',fontSize:'0.5rem'}} >
                        <p className="text-sm text-center w-full pt-16">
                            MOVIEKEY™ trademark of MOVIEKEY USA LLC.
                        </p>
                        <p className="text-sm text-center pt-2 pb-12">                
                            <Link className="text-stone-400 font-semibold" to="/terms" style={{color:'#A8A29E',margin:'10px'}}>Terms and Conditions</Link>&nbsp;
                            <Link className="sm:inline-block block text-stone-400 font-semibold px-3" to="/privacy"  style={{color:'#A8A29E',margin:'10px'}}>Privacy Policy</Link>
                        </p>
                        </div>
                        </FadeInSection>
                        </div>
                        
                        </div>

                    </div>
                </div> 


                {!openNewsLetter && scrollPosition && scrollPosition > 580 && window.innerWidth < 1000 && <div className='upper-banner' style={{padding:'16px 15px 8px' ,zIndex:'999' }}>
                    <img src={topBanner} style={{width:'100%',maxWidth:'1000px',margin:'auto'}} />
                </div>}
                

                {!openNewsLetter && scrollPosition && scrollPosition > 20 && window.innerWidth > 1000 &&  <div className='upper-banner width-adjuster' style={{padding:'16px 15px 8px' ,zIndex:'999' }}>
                    <img src={topBanner} style={{width:'100%',maxWidth:'1000px',margin:'auto'}} />
                </div>}
                
            {!openNewsLetter && scrollPosition && scrollPosition > 730 && <div className="mobile-cta fade-me" style={{height:'134px',paddingTop:'12px'}}>
                {/* <div className='mobile-heading' style={{fontSize:'16px'}}> Purchase to watch online anytime </div>            */}
                <a href='https://watch.nftme.tv/connect?action=checkout' style={{padding:'9.8px 9px'}} className="main-buy-btn pre-order-btn new-red-buy">
                    
                    <p style={{ margin:'0px',fontSize:'16px',lineHeight:'1' }} >BUY SERIES $12.99</p>
                    <p style={{fontSize:'12px',fontWeight:'500',margin:'0px',lineHeight:'1',textTransform:'lowercase'}}  className='museo-sans'>+ transaction fee</p>

                </a>

                {/* 
                <a onClick={()=>setOpenNewsLetter(true)} style={{padding:'14px',margin:'0px',backgroundColor:'#1d1d1d',fontSize:'16px',border:'1px solid #959595'}} className="main-buy-btn pre-order-btn new-grey-btn"> Remind Me Later </a>
                */}

                <a onClick={()=>setOpenNewsLetter(true)} style={{padding:'14px 12px',margin:'0px',fontSize:'16px'}} className="main-buy-btn pre-order-btn new-grey-btn"> Remind Me Later </a>
                            
                <div className='new-secure' style={{width:'352px',margin:'12px auto'}}>
                    <img src={safeLogo} style={{width:'30px',height:'32px'}} />
                    <p>Guaranteed Safe & Secure Checkout</p>
                </div>
                
            </div>}



            <Snackbar
              open={alertState.open}
              autoHideDuration={10000}
              anchorOrigin={{vertical: 'top', horizontal: 'center' }}
              onClose={() => setAlertState({ ...alertState, open: false })}>
                <Alert
                  onClose={() => setAlertState({ ...alertState, open: false })}
                  severity={alertState.severity}>
                  {alertState.message}
                </Alert>
            </Snackbar> 
        </>
    )
}


function FadeInSection(props: any) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef: any = React.useRef();

    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);

    return (
      <div className={`hm-fade-in ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
        {props.children}
      </div>
    );
}

export {Home}
