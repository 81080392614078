import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
    const [isVisible, setIsVisibile] = useState(false)

    const l = useLocation()
    useEffect(() => {
        setIsVisibile(l.pathname == "/" || l.pathname == "/bbefb" || l.pathname ==  "/bbemovie" || l.pathname =="/BBEfb" || l.pathname =="/BBEmoviefb" || l.pathname == "/mintBBE" || l.pathname == "/watchBBEmovie" || l.pathname == "/watchbbemovie" || l.pathname == "/BBE" || l.pathname == "/watchBBEnow" ? false : true);
    }, [l.pathname])


    return isVisible ? (
        <div className="px-3 sm:px-6 max-w-[1169px] mx-auto w-full " style={{marginTop:'50px',fontSize:'0.5rem'}} >
                        <p className="text-sm text-center w-full pt-16">
                            MOVIEKEY™ trademark of MOVIEKEY USA LLC.
                        </p>
                        <p className="text-sm text-center pt-2 pb-12">                
                            <Link className="text-stone-400 font-semibold" to="/terms" style={{color:'#A8A29E',margin:'10px'}}>Terms and Conditions</Link>&nbsp;
                            <Link className="sm:inline-block block text-stone-400 font-semibold px-3" to="/privacy"  style={{color:'#A8A29E',margin:'10px'}}>Privacy Policy</Link>
                        </p>
                        </div>
    ) : null;
};

export default Footer;
