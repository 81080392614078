import "./Terms.css"

export default function Privacy() {
    return (
        <div className="terms">
            <div className="terms-body">
                <div className="terms-section">
                    <h1 className="terms-title">MovieKey USA LLC</h1>
                    <h1 className="terms-title">moviekey.io</h1>
                    <h1 className="terms-title">PRIVACY POLICY</h1>
                </div>

                <div className="terms-section">
                    <h1>1. Our Commitment to Privacy</h1>
                    <p>Your privacy is important to us. To better protect your privacy, we provide this notice explaining our online information practices.</p>
                </div>

                <div className="terms-section">
                    <h1>2. The Information We Collect</h1>
                    <p>This notice applies to all information collected or submitted on the Platform. This information may include, but is not limited to: <br/><br/> - Name <br/>- Email address <br/>- Date of birth <br/>- Gender <br/>- IP Address <br/>- Browser and device Information <br/>- Viewer metrics <br/>- Purchase and payment details </p>
                </div>

                <div className="terms-section">
                    <h1>3. The Way We Use Information</h1>
                    <p>We use the information you provide about yourself when purchasing the MovieKey to complete your Profile. We do not share this information with outside parties except to the extent required by law or advisable to the intended running of this site and processing your payment via our third-party card payment provider, Crossmint. Your email, name and credit card details are not shown publicly. We use return email addresses to answer the email we receive. Such addresses are not used for any other purpose and are not shared with outside parties. We use non-identifying and aggregate information to better design our website and to share with advertisers. For example, we may tell an advertiser that X number of individuals visited a certain area on our website, or that Y number of men and Z number of women filled out our registration form, but we would not disclose anything that could be used to identify those individuals</p>
                </div>

                <div className="terms-section">
                    <h1>4. Our Commitment to Data Security</h1>
                    <p>To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online. </p>
                </div>

                <div className="terms-section">
                    <h1>5. Our Commitment to Children’s Privacy</h1>
                    <p>Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our website from those we actually know are under 13, and no part of our website is structured to attract anyone under 13. </p>
                </div>
                    
                <div className="terms-section">
                    <h1>6. Use of Third Party Services</h1>
                    <p>**6.1 Google Analytics <br/>
We use Google Analytics to help us to understand how you use our site and tools, which allows us to know how to improve our products for your benefit. It follows your progress through the website, collecting anonymous data on where you have come from, which pages you visit, and how long you spend on the site. Google then stores this data in order to create reports. But don’t worry; they do not store your personal data. Google will track your IP address, and it may be transmitted to and stored by Google on servers in the United States. Google may use this information for the purpose of evaluating your use of the website, compiling reports on website activity for us, and providing other services relating to website activity and Internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. Click here for more information on Google’s privacy policies. . Google Analytics services are governed by the Google Analytics Terms of Service which can be found here.
<br/><br/> **6.2 Crossmint<br/>
We use a third-party payment processor, Crossmint, to process payments made to us. In connection with the processing of such payments, we do not retain any personally identifiable information or any financial information such as credit card numbers. Rather, all such information is provided directly to our third-party processor, Crossmint, whose use of your personal information is governed by their privacy policy, which may be viewed here. Crossmint is powered by Stripe which is certified to PCI Service Provider Level 1, the most stringent level of certification. 
</p>
                </div>
            </div>
        </div>
    )
}
